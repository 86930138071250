//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { UserModel } from '@/modules/auth/user-model';
import i18n from '@/vueI18n';

const { fields } = UserModel;

export default {
  name: 'app-signin-page',

  data() {
    return {
      rules: {
        email: fields.email.forFormRules(),
        password: fields.password.forFormRules(),
        rememberMe: fields.rememberMe.forFormRules(),
      },
      model: {
        rememberMe: true,
        password: '',
      },
      isPwd: true,
    };
  },

  computed: {
    ...mapGetters({
      loading: 'auth/loading',
      is_screen_xs: 'layout/is_screen_xs',
      is_labtop: 'layout/is_labtop',
    }),

    fields() {
      return fields;
    },
    getProgress() {
      let progress = 0

      // at least one number
      if (/\d/.test(this.model.password)) {
        progress += 20
      }

      // at least one capital letter
      if (/(.*[A-Z].*)/.test(this.model.password)) {
        progress += 20
      }

      // at menons a lowercase
      if (/(.*[a-z].*)/.test(this.model.password)) {
        progress += 20
      }

      // more than 5 digits
      if (this.model.password.length >= 6) {
        progress += 20
      }

      // at least one special character
      if (/[^A-Za-z0-9]/.test(this.model.password)) {
        progress += 20
      }

      return progress
    },
    language(){
      return i18n.locale
    }
  },
  watch: {
    language() {
               document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('auth.signin') 
    },
  },

  methods: {
    ...mapActions({
      doSigninSocial: 'auth/doSigninSocial',
      doSigninWithEmailAndPassword:
        'auth/doSigninWithEmailAndPassword',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      await this.doSigninWithEmailAndPassword({
        email: this.model.email,
        password: this.model.password,
        rememberMe: this.model.rememberMe,
      });
    },
  },
};
